/* eslint-disable complexity */
/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable max-lines-per-function */

import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react"
import { Icosahedron, Text } from "@react-three/drei"
import {
    ArrowHelper, BackSide, Box3, Box3Helper, BoxHelper, BufferGeometry, Color, DoubleSide, Group, Line,
    LineBasicMaterial, Mesh, Object3D, Quaternion, Raycaster, Scene, Vector3
} from "three"
import { useFrame, useThree } from "@react-three/fiber"
import { MarkerType, PartTypeEnum } from "../../../../../../../../utils/Types"
import { SectionType, SegmentedTubeInfo, SegmentedTubeMarker } from "../types/types"
import { MeshUtils } from "../../../../../../../../utils/MeshUtils"
import { addPartModal, isItemSelected } from "../../../../../../../../state/atoms"
import { useSetRecoilState } from "recoil"
import { debounce as debounceLodash } from "lodash"

import { ConnectionOfPart } from "../../../../../../../../state/scene/types"
import { useLevaControls } from "../../../../../../../../providers/debugProvider/useLevaControls"
import { SceneRef } from "../../../../../../../../state/types"
export type SectionedMarkerData = {
    section: SectionType, key: string, position?: string,
}

type NewPartButtonProps = {
    info: MutableRefObject<SegmentedTubeInfo>,
    marker: SegmentedTubeMarker,
    partId: string,
    setIsSelected: (selected: boolean) => void,
    isMiddleSection?: boolean,
    scene?: Scene,
    partConnectionsValue: ConnectionOfPart[],
    length: number,
    relevantMeshes: Object3D[],
    sceneRefs: SceneRef,
}


const NewPartButton = (props: NewPartButtonProps) => {
    const setNewConnectionData = useSetRecoilState(addPartModal)

    const { camera, } = useThree()
    const icoRef = useRef<Mesh>(null)


    const buttonBox = useMemo(() => {
        const box = new Box3()
        if (props.marker.outer) {
            // Get world position from the mesh
            const worldPosition = new Vector3()
            props.marker.outer.getWorldPosition(worldPosition)

            const size = 0.02
            box.min.set(-size, -size, -size)
            box.max.set(size, size, size)
            box.translate(worldPosition)
        }
        return box
    }, [props.marker.outer,])


    useEffect(() => {
        if (!props.sceneRefs?.current?.cameraControls || !props.sceneRefs?.current?.calculateSizeOfBoxFactor) {
            return
        }
        const cam = props.sceneRefs.current.cameraControls.current
        if (!cam) {
            return
        }

        //updating of 2d position of markers on the screen for snapping logic
        //you need a slight timeout bc the camera still moved for a little right after it says
        //controlend

        const debouncedUpdate = debounceLodash(() => {
            if (buttonBox && props.sceneRefs.current.calculateSizeOfBoxFactor) {
                const size = props.sceneRefs.current.calculateSizeOfBoxFactor(buttonBox, false)
                if (size && size.result !== undefined) {
                    if (size.result === buttonSize) {
                    } else {
                        setButtonSize(size.result)
                    }
                }
            }
        }, 100,)


        const update = (() => {
            //console.log("update")
            debouncedUpdate()
        })

        update()


        // we need both
        cam.addEventListener("update", update)
        cam.addEventListener("transitionstart", update)

        // Clean up
        return () => {
            cam.removeEventListener("update", update)
            cam.removeEventListener("transitionstart", update)
        }
    }, [buttonBox,])

    const [hovered, hover,] = useState(false)
    const [buttonSize, setButtonSize,] = useState(0.02)
    const [makeSeeThrough, setMakeSeeThrough,] = useState(true)
    const targetMarker = props.marker.plus || props.marker.outer!
    const { segmentedPlusButtonPosDebug, } = useLevaControls()
    const { worldOuterPosition, worldOuterQuaternion, outerMarkerWorldPos, outerMarkerWorldRotation, innerMarkerWorldPos, innerMarkerWorldRotation, } = useMemo(() => {
        const worldOuterPosition = MeshUtils.copyWorldPosition(targetMarker)
        const worldOuterDirection = MeshUtils.copyWorldDirection(targetMarker)
        worldOuterPosition.add(worldOuterDirection.multiplyScalar(buttonSize))
        const outerMarkerWorldPos = MeshUtils.copyWorldPosition(props.marker.outer!)
        const outerMarkerWorldRotation = MeshUtils.copyWorldQuaternion(props.marker.outer!)
        const innerMarkerWorldPos = MeshUtils.copyWorldPosition(props.marker.inner!)
        const innerMarkerWorldRotation = MeshUtils.copyWorldQuaternion(props.marker.inner!)
        const worldOuterQuaternion = MeshUtils.copyWorldQuaternion(targetMarker)
        return { worldOuterPosition, worldOuterDirection, worldOuterQuaternion, outerMarkerWorldPos, outerMarkerWorldRotation, innerMarkerWorldPos, innerMarkerWorldRotation, }
    }, [props.marker, isItemSelected, props.partConnectionsValue, props.length, buttonSize,])



    const [markerPosition, setMarkerPosition,] = useState(new Vector3())

    const [offset, setOffset,] = useState(0)

    const boxHelperRef = useRef<BoxHelper | null>(null)

    const idealPosition: Vector3 = useMemo(() => {
        const calculateIdealPosition = (
            longSide: number,
            isVertical: boolean,
            worldOuterWorking: Vector3,
            worldOuterDirectionWorking: Vector3,
            worldOuterQuaternionWorking: Quaternion
        ) => {
            const increments = Array.from({ length: Math.ceil(longSide / 0.0125), },
                (_, i) => (i + 1) * 0.0125)
            const directions = [0, ...increments, ...increments.map(inc => -inc),]

            // Define axis based on isVertical
            const axis = isVertical
                ? { x: 0, y: 1, z: 0, } // y-axis for vertical
                : { x: 1, y: 0, z: 0, } // x-axis for horizontal

            // Size of the button box for intersection testing

            const buttonHalfSize = buttonSize / 2

            // Create a box for intersection testing
            const buttonBox = new Box3(
                new Vector3(-buttonHalfSize, -buttonHalfSize, -buttonHalfSize),
                new Vector3(buttonHalfSize, buttonHalfSize, buttonHalfSize)
            )



            for (const direction of directions) {
                const localOffset = new Vector3(
                    axis.x * direction,
                    axis.y * direction,
                    axis.z * direction
                )

                localOffset.applyQuaternion(worldOuterQuaternionWorking)
                const testPosition = worldOuterWorking.clone().add(localOffset)

                // Add a small offset in the direction the marker is facing
                const plusOffset = new Vector3(0, 0, buttonSize)
                    .applyQuaternion(worldOuterQuaternionWorking)
                testPosition.add(plusOffset)

                // Position the box at the test position
                const positionedBox = buttonBox.clone()
                positionedBox.translate(testPosition)

                // Remove previous helper if it exists
                if (boxHelperRef.current && props.scene && segmentedPlusButtonPosDebug) {
                    props.scene.remove(boxHelperRef.current)
                    boxHelperRef.current = null
                }

                // Create a box helper for visualization
                const boxMesh = new Mesh(
                    new BufferGeometry().setFromPoints([
                        new Vector3(-buttonHalfSize, -buttonHalfSize, -buttonHalfSize),
                        new Vector3(buttonHalfSize, -buttonHalfSize, -buttonHalfSize),
                        new Vector3(buttonHalfSize, buttonHalfSize, -buttonHalfSize),
                        new Vector3(-buttonHalfSize, buttonHalfSize, -buttonHalfSize),
                        new Vector3(-buttonHalfSize, -buttonHalfSize, buttonHalfSize),
                        new Vector3(buttonHalfSize, -buttonHalfSize, buttonHalfSize),
                        new Vector3(buttonHalfSize, buttonHalfSize, buttonHalfSize),
                        new Vector3(-buttonHalfSize, buttonHalfSize, buttonHalfSize),
                    ])
                )
                boxMesh.position.copy(testPosition)


                if (props.scene && segmentedPlusButtonPosDebug) {
                    const boxHelper = new BoxHelper(boxMesh, 0xff0000)
                    boxHelper.userData = { ignoreRaycast: true, }
                    props.scene.add(boxHelper)
                    boxHelperRef.current = boxHelper
                }

                // Check for intersections
                let hasIntersection = false

                for (const object of props.relevantMeshes) {
                    // Skip objects that should be ignored

                    // Get the object's bounding box
                    const objectBox = new Box3().setFromObject(object, true)

                    // Check for intersection
                    if (positionedBox.intersectsBox(objectBox)) {
                        hasIntersection = true
                        break
                    }

                }

                // If no intersection, use this position
                if (!hasIntersection) {
                    setOffset(direction)
                    setMarkerPosition(testPosition)

                    // Keep the debug box visible for the chosen position
                    if (boxHelperRef.current && segmentedPlusButtonPosDebug) {
                        boxHelperRef.current.material.color.set(0x00ff00) // Green for success
                    }

                    return testPosition
                } else if (boxHelperRef.current && segmentedPlusButtonPosDebug) {
                    // Red for intersection
                    boxHelperRef.current.material.color.set(0xff0000)
                }
            }

            setMakeSeeThrough(false)
            return null
        }

        const worldOuterWorking = MeshUtils.copyWorldPosition(targetMarker)
        const worldOuterDirectionWorking = MeshUtils.copyWorldDirection(targetMarker)
        const worldOuterQuaternionWorking = MeshUtils.copyWorldQuaternion(targetMarker)

        if (props.marker.mesh) {
            props.marker.mesh.geometry.computeBoundingBox()
            const boundingBox = props.marker.mesh.geometry.boundingBox
            if (boundingBox) {
                const meshWidth = boundingBox.max.x - boundingBox.min.x
                const meshHeight = boundingBox.max.y - boundingBox.min.y
                const longSide = Math.max(meshWidth, meshHeight) / 2 - 0.0125
                return calculateIdealPosition(longSide, false, worldOuterWorking,
                    worldOuterDirectionWorking, worldOuterQuaternionWorking) || new Vector3()
            }
        } else if (props.marker.outer && props.marker.outer.userData.boundary) {
            const longSide = props.marker.outer.userData.boundary * 2.54 / 100
            return calculateIdealPosition(longSide, true, worldOuterWorking,
                worldOuterDirectionWorking, worldOuterQuaternionWorking) || new Vector3()
        }

        return new Vector3()
    }, [props.partConnectionsValue, isItemSelected, buttonSize,])

    const cameraVector = new Vector3()
    useFrame((state) => {
        if (icoRef.current) {
            camera.getWorldPosition(cameraVector)
            icoRef.current.lookAt(cameraVector)
        }
    })

    const onHover = () => {
        hover(true)
        //console.log(props.marker.outer?.name, "marker.outer")
    }

    const onHoverOut = () => {
        hover(false)
    }

    useEffect(() => {
        if (hovered) {
            document.body.style.cursor = "pointer"
        } else {
            document.body.style.cursor = "default"
        }

        return () => {
            document.body.style.cursor = "default"
        }
    }, [hovered,])

    const handleNewPart = () => {

        props.setIsSelected(false)
        document.body.style.cursor = "default"

        const applyOffset = (position: Vector3, isVertical: boolean) => {
            const offsetVector = isVertical
                ? new Vector3(0, offset, 0)
                : new Vector3(offset, 0, 0)
            offsetVector.applyQuaternion(worldOuterQuaternion)
            const clonedPosition = new Vector3().copy(position)
                .add(offsetVector)
            return clonedPosition
        }

        //using instead values from useMemo
        //const outerPos = MeshUtils.copyWorldPosition(props.marker.outer!)
        //const outerRot = MeshUtils.copyWorldQuaternion(props.marker.outer!)
        //const innerPos = MeshUtils.copyWorldPosition(props.marker.inner!)
        //const innerRot = MeshUtils.copyWorldQuaternion(props.marker.inner!)

        let innerPos = innerMarkerWorldPos
        let outerPos = outerMarkerWorldPos

        if (props.marker.mesh && !markerPosition.equals(new Vector3())) {
            outerPos = applyOffset(outerMarkerWorldPos, false)
            innerPos = applyOffset(innerMarkerWorldPos, false)
        } else if (props.marker.outer && props.marker.outer.userData.boundary) {
            outerPos = applyOffset(outerMarkerWorldPos, true)
            innerPos = applyOffset(innerMarkerWorldPos, true)
        }

        //console.log(props.marker.outer, "props.marker.outer")

        setNewConnectionData({
            step1: {
                source: {
                    partId: props.partId,
                    markerId: props.marker.outer!.userData.id,
                    markerName: props.marker.outer!.name,
                    posAndRot: {
                        inner: {
                            pos: innerPos,
                            rot: innerMarkerWorldRotation,
                        },
                        outer: {
                            pos: outerPos,
                            rot: outerMarkerWorldRotation,
                        },
                    },
                    connectionLength: props.marker.outer!.userData.iELength,
                    sizeId: props.marker.outer!.userData.sizeId,
                    expandReduceToFitInfo: { isApplicable: false, },
                    type: PartTypeEnum.segmentedTube,
                    canSlide: props.isMiddleSection,
                },
            },
        })
    }

    const materialColor = useMemo(() => {
        return new Color(hovered ? "#43a7ff" : "#0088ff").convertSRGBToLinear()
    }, [hovered,])

    // Clean up the box helper when component unmounts
    useEffect(() => {
        return () => {
            if (boxHelperRef.current && props.scene && segmentedPlusButtonPosDebug) {
                props.scene.remove(boxHelperRef.current)
                boxHelperRef.current = null
            }
        }
    }, [segmentedPlusButtonPosDebug,])

    return <Icosahedron
        userData={{
            type: MarkerType.PLUS_BUTTON,
        }}
        ref={icoRef}
        name={"Plus Button"}
        args={[buttonSize / 2, 3,]}
        position={markerPosition.equals(new Vector3()) ? worldOuterPosition : markerPosition}
        quaternion={worldOuterQuaternion}
        onPointerLeave={onHoverOut}
        onPointerEnter={onHover}
        renderOrder={1}
        //use PointerDown instead of onClick bc on mobile, sometimes it does not get recognized
        onPointerDown={(e) => {
            e.stopPropagation()
            //handleNewPart()
        }}
        onPointerUp={(e) => {
            e.stopPropagation()
            handleNewPart()
        }}
        onClick={(e) => {
            e.stopPropagation()
        }}
    >
        <meshBasicMaterial
            color={materialColor}
            side={BackSide}
            depthTest={makeSeeThrough}
            depthWrite={makeSeeThrough}
            transparent={!makeSeeThrough}
            opacity={makeSeeThrough ? 1 : 0.6}
        />
        <Text fontSize={buttonSize} color={"#fff"} renderOrder={2}  >
            <meshBasicMaterial
                color="#ffffff"
                depthTest={makeSeeThrough}
                depthWrite={makeSeeThrough}
                opacity={makeSeeThrough ? 1 : 0.7}
            />
            +
        </Text>
    </Icosahedron>
}

export default NewPartButton